import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import UPILOGO from "../Assets/Images/hdfc.png";
import VERIFIED from "../Assets/Images/verified.png";
import GPAY from "../Assets/Images/gpay.png";
import PHONEPAY from "../Assets/Images/phonePay.png";
import PAYTM from "../Assets/Images/PAYTM.png";

const QRCodeDetails = () => {
  const { id } = useParams();
  const [qrData, setQrData] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchQRData = async () => {
      try {
        const response = await axios.get(
          `https://d-backend-x7ye.onrender.com/api/qr/${id}`
        );
        setQrData(response.data);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setError("QR Code not found.");
        } else {
          setError("Failed to fetch QR data.");
        }
        console.error("Error fetching QR data:", error);
      }
    };
    fetchQRData();
  }, [id]);

  const handleBack = () => {
    navigate("/");
  };

  const handlePayNow = () => {
    // Detect device type
    const isIphone = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    const isAndroid = /Android/i.test(navigator.userAgent);
  
    const upiUrl = `upi://pay?pa=${qrData.upiId}&pn=${encodeURIComponent(
      qrData.name
    )}&am=${qrData.amount}&cu=INR&tn=${encodeURIComponent("Payment")}`;
  
    if (isIphone) {
      // For iPhone, show app options for UPI apps
      setShowAppOptions(true);
    } else if (isAndroid) {
      // For Android, just use the default UPI URL scheme
      window.location.href = upiUrl;
    } else {
      // If the device is neither Android nor iPhone (e.g., desktop), show an alert or fallback URL
      alert("UPI payment is only available on mobile devices.");
    }
  };
  
  const [showAppOptions, setShowAppOptions] = useState(false);
  

  return (
    <div style={styles.container}>
    <div style={styles.card}>
      <div style={styles.header}>
        <img src={UPILOGO} alt="UPI Logo" style={styles.upiLogo} />
        <div style={styles.verifiedName}>
          <h2 style={styles.upiName}>Verified Business:</h2>
          <h2 style={styles.businessName}>
            {qrData?.name || "UPI Transaction"}
          </h2>
          <img src={VERIFIED} alt="Verified" style={styles.verifiedIcon} />
        </div>
      </div>
      {error ? (
        <p style={styles.errorText}>{error}</p>
      ) : qrData ? (
        <div style={styles.qrContainer}>
          <img src={qrData.qrCodeUrl} alt="QR Code" style={styles.qrImage} />
          <div style={styles.details}>
            <p>
              <strong>Policy Number:</strong> {qrData.policyNumber}
            </p>
            <p>
              <strong>Amount:</strong> ₹{qrData.amount}
            </p>
          </div>
          <button onClick={handlePayNow} style={styles.payNowButton}>
            Pay Now
          </button>
          {showAppOptions && (
            <div style={styles.appOptions}>
              <h2>Select Your UPI App</h2>
            <div style={{display:"flex", alignItems:"center"}}>
            <a
                href={`gpay://upi/pay?pa=${qrData.upiId}&pn=${encodeURIComponent(qrData.name)}&am=${qrData.amount}&cu=INR`}
                style={styles.appLink}
              >
                <img style={{width:"70px"}} src={GPAY}/>
              </a>
              <br />
              <a
                href={`phonepe://upi/pay?pa=${qrData.upiId}&pn=${encodeURIComponent(qrData.name)}&am=${qrData.amount}&cu=INR`}
                style={styles.appLink}
              >
                 <img style={{width:"70px"}} src={PHONEPAY}/>
              </a>
              <br />
              <a
                href={`paytmmp://upi/pay?pa=${qrData.upiId}&pn=${encodeURIComponent(qrData.name)}&am=${qrData.amount}&cu=INR`}
                style={styles.appLink}
              >
                 <img style={{width:"70px"}} src={PAYTM}/>
              </a>
            </div>
            </div>
          )}
        </div>
      ) : (
        <p style={styles.loadingText}>Loading...</p>
      )}
      <button onClick={handleBack} style={styles.button}>
        Back
      </button>
    </div>
    <img
      style={styles.bottomImg}
      src="https://imatrivandrum.org/wp-content/uploads/2023/01/IMA-Trivandrum-payments.png"
      alt="IMA Trivandrum"
    />
  </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "100vh",
    backgroundColor: "#ffffff",
    fontFamily: "'Roboto', sans-serif",
    padding: "10px",
    boxSizing: "border-box",
  },
  card: {
    backgroundColor: "#ffffff",
    width: "100%",
    maxWidth: "400px",
    padding: "20px",
    borderRadius: "15px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    background: "#E9EFEC",
    padding: "10px",
    borderRadius: "10px",
    gap: "10px",
  },
  upiLogo: {
    width: "100px",
    height: "80px",
    backgroundColor: "white",
    objectFit: "contain",
    borderRadius: 15,
    padding: "10px",
  },
  verifiedName: {
    display: "flex",
    alignItems: "center",
    gap: "3px",
    padding: "10px 15px",
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#F9F9F9",
  },
  upiName: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: "#00008B",
    margin: 0,
  },
  businessName: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: "#333",
    margin: 0,
  },
  verifiedIcon: {
    width: "20px",
    height: "20px",
    marginLeft: "8px",
  },
  errorText: {
    color: "red",
    fontSize: "1rem",
    marginTop: "10px",
  },
  loadingText: {
    fontSize: "1rem",
    color: "#333",
    marginTop: "20px",
  },
  qrContainer: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  qrImage: {
    width: "200px",
    height: "200px",
    marginBottom: "20px",
  },
  details: {
    marginTop: "10px",
    fontSize: "1rem",
    color: "#333",
    textAlign: "center",
  },
  button: {
    backgroundColor: "#4facfe",
    color: "white",
    padding: "12px 20px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    marginTop: "20px",
    width: "100%",
  },

  downloadButton: {
    backgroundColor: "#28a745",
    color: "white",
    padding: "12px 20px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    marginTop: "20px",
    width: "100%",
  },
  bottomImg: {
    width: "80%",
    marginTop: "40px",
  },
  payNowButton: {
    backgroundColor: "#ff5722",
    color: "white",
    padding: "12px 20px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    marginTop: "20px",
    width: "100%",
  },
  appOptions: {
    marginTop: "20px",
    fontSize: "1rem",
    color: "#333",
    textAlign: "center",
  },
  appLink: {
    color: "#0078D4",
    textDecoration: "none",
    fontSize: "1.1rem",
    margin: "10px 0",
  },
  
};

export default QRCodeDetails;
