import React from "react";

const UnderMaintenance = () => {
  return (
    <div style={styles.container}>
      <div>
        <h1 style={styles.heading}>Website Under Maintenance</h1>
      </div>
      <div>
        <p style={styles.message}>We're working hard to improve our website. Please check back soon!</p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    textAlign: "center",
    backgroundColor: "#f4f4f4",
  },
  heading: {
    fontSize: "2.5rem",
    color: "#333",
    marginBottom: "20px", // Increased margin between heading and message
  },
  message: {
    fontSize: "1.2rem",
    color: "#777",
  },
};

export default UnderMaintenance;
